import React from 'react';
import './App.css';
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textfield: {
    spacing: 1,
    color: "purple"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function CaseRow(props) {
  const {
      beginsExpanded,
      scenarioName,
      textChange,
      homePrice,
      downpaymentPercentage,
      interestRate,
      loanTerm,
      roommateIncome,
      realtorCommission,
      annualAppreciation,
      rentOtherwisePay,
      discountRate,
      monthlyMaintenance,
      inflation,
      index
  } = props;
  const [expanded, setExpanded] = React.useState(beginsExpanded);
  const classes = useStyles();

  const handleChange = () => (event, newExpanded) => {
    setExpanded(newExpanded ? true : false);
  };

  const textXS = 1.1;

  return (
    <MuiAccordion expanded={expanded} onChange={handleChange()}>
      <MuiAccordionSummary>
        <Grid container spacing={1}>
          <Grid item xs={textXS}>
            <TextField
              label="Scenario Name"
              id="scenarioName"
              defaultValue={scenarioName}
              variant="outlined"
              size="small"
              onChange={textChange}
            />
          </Grid>
        </Grid>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
          <Grid container spacing={1}>
              <Grid item xs={textXS}>
                <TextField
                  label="Home Price"
                  id="homePrice"
                  defaultValue={homePrice}
                  variant="outlined"
                  size="small"
                  onChange={(e) => textChange(e, index, "homePrice")}
                />
              </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Downpayment Fraction"
                id="downpaymentPercentage"
                defaultValue={downpaymentPercentage}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "downpaymentPercentage")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Interest Rate"
                id="interestRate"
                defaultValue={interestRate}
                variant="outlined"
                size="small"
                className={classes.textfield}
                onChange={(e) => textChange(e, index, "interestRate")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Loan Term"
                id="loanTerm"
                defaultValue={loanTerm}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "loanTerm")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Roommate Income"
                id="roommateIncome"
                defaultValue={roommateIncome}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "roommateIncome")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Realtor Commission"
                id="realtorCommission"
                defaultValue={realtorCommission}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "realtorCommission")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Annual Appreciation"
                id="annualAppreciation"
                defaultValue={annualAppreciation}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "annualAppreciation")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Rent You'd Otherwise Pay"
                id="rentOtherwisePay"
                defaultValue={rentOtherwisePay}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "rentOtherwisePay")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Discount Rate"
                id="discountRate"
                defaultValue={discountRate}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "discountRate")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Insurance, Taxes, & Upkeep"
                id="monthlyMaintenance"
                defaultValue={monthlyMaintenance}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "monthlyMaintenance")}
              />
            </Grid>
            <Grid item xs={textXS}>
              <TextField
                label="Inflation"
                id="inflation"
                defaultValue={inflation}
                variant="outlined"
                size="small"
                onChange={(e) => textChange(e, index, "inflation")}
              />
            </Grid>
          </Grid>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}

export default CaseRow;

