import React from 'react';
import {
  Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Label, ComposedChart, Area
} from 'recharts';


function Plotter(props) {
    const {plotData, scenarioInputs} = props;

    const gradientOffset = (key) => {
      console.log(key);
      const dataMax = Math.max(...plotData.map(i => {
          if (typeof i[key] !== 'undefined') {
              return i[key]
          } else {
              return 0
          }
      }));
      const dataMin = Math.min(...plotData.map(i => {
          if (typeof i[key] !== 'undefined') {
              return i[key]
          } else {
              return 0
          }
      }));

      if (dataMax <= 0) {
        return 0;
      }
      if (dataMin >= 0) {
        return 1;
      }
      return dataMax / (dataMax - dataMin);
    };


    const off0 = gradientOffset(scenarioInputs[0].scenarioName);
    const off1 = gradientOffset(scenarioInputs[1].scenarioName);
    const off2 = gradientOffset(scenarioInputs[2].scenarioName);
    const off3 = gradientOffset(scenarioInputs[3].scenarioName);
    const off4 = gradientOffset(scenarioInputs[4].scenarioName);
    return (
        <ResponsiveContainer width="95%" height={500}>
          <ComposedChart
            data={plotData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month">
                  <Label value="Months of Ownership" offset={0} position="insideBottom" />

              </XAxis>
              <YAxis label={{ value: 'Net Present Value of Purchasing', angle: -90, position: 'insideBottomLeft' }}/>
              {/*<Tooltip />*/}
              <Legend verticalAlign="top"  />
              <Line type="monotone" dataKey={scenarioInputs[0].scenarioName} stroke="#f44336" />
              <Line type="monotone" dataKey={scenarioInputs[1].scenarioName} stroke="#9c27b0" />
              <Line type="monotone" dataKey={scenarioInputs[2].scenarioName} stroke="#00bcd4" />
              <Line type="monotone" dataKey={scenarioInputs[3].scenarioName} stroke="#009688" />
              <Line type="monotone" dataKey={scenarioInputs[4].scenarioName} stroke="#cddc39" />
              <Area type="monotone" legendType={"none"} label={null} dataKey={scenarioInputs[0].scenarioName}  fill="url(#splitColor0)" stroke="#000"/>
                <defs>
                  <linearGradient id="splitColor0" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off0} stopColor="green" stopOpacity={0.1} />
                    <stop offset={off0} stopColor="red" stopOpacity={0.2} />
                  </linearGradient>
                </defs>
              <Area type="monotone" legendType={"none"} label={null} dataKey={scenarioInputs[1].scenarioName}  fill="url(#splitColor1)" stroke="#000"/>
                <defs>
                  <linearGradient id="splitColor1" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off1} stopColor="green" stopOpacity={.1} />
                    <stop offset={off1} stopColor="red" stopOpacity={.2} />
                  </linearGradient>
                </defs>
              <Area type="monotone" legendType={"none"} label={null} dataKey={scenarioInputs[2].scenarioName}  fill="url(#splitColor2)" stroke="#000"/>
                <defs>
                  <linearGradient id="splitColor2" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off2} stopColor="green" stopOpacity={.1} />
                    <stop offset={off2} stopColor="red" stopOpacity={.2} />
                  </linearGradient>
                </defs>
              <Area type="monotone" legendType={"none"} label={null} dataKey={scenarioInputs[3].scenarioName}  fill="url(#splitColor3)" stroke="#000"/>
                <defs>
                  <linearGradient id="splitColor3" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off3} stopColor="green" stopOpacity={.1} />
                    <stop offset={off3} stopColor="red" stopOpacity={.2} />
                  </linearGradient>
                </defs>
              <Area type="monotone" legendType={"none"} label={null} dataKey={scenarioInputs[4].scenarioName}  fill="url(#splitColor4)" stroke="#000"/>
                <defs>
                  <linearGradient id="splitColor4" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off4} stopColor="green" stopOpacity={.1} />
                    <stop offset={off4} stopColor="red" stopOpacity={.2} />
                  </linearGradient>
                </defs>
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default Plotter;