import React, {useState, useCallback, useEffect} from 'react';
import './App.css';
import CaseRow from "./CaseRow";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Plotter from "./Plotter";
import Typography from "@material-ui/core/Typography";


const defaultScenarioInputs = [
  {'homePrice': 250000,
   'downpaymentPercentage': 0.2,
   'interestRate': 0.04,
   'loanTerm': 30,
   'roommateIncome': 0,
   'realtorCommission': 0.07,
   'annualAppreciation': 0.01,
   'rentOtherwisePay': 1600,
   'discountRate': 0.05,
   'monthlyMaintenance': 200,
   'scenarioName': 'Baseline',
   'beginsExpanded': true,
   'taxRate': 0.35,
   'scenarioCode': 'A',
   'inflation': 0.01
  },
  {'homePrice': 250000,
   'downpaymentPercentage': 0.2,
   'interestRate': 0.04,
   'loanTerm': 15,
   'roommateIncome': 0,
   'realtorCommission': 0.07,
   'annualAppreciation': 0.01,
   'rentOtherwisePay': 1600,
   'discountRate': 0.05,
   'monthlyMaintenance': 200,
   'scenarioName': '15 yr Mortgage',
   'beginsExpanded': false,
   'taxRate': 0.35,
   'scenarioCode': 'B',
   'inflation': 0.01
  },
  {'homePrice': 250000,
   'downpaymentPercentage': 0.2,
   'interestRate': 0.04,
   'loanTerm': 30,
   'roommateIncome': 600,
   'realtorCommission': 0.07,
   'annualAppreciation': 0.01,
   'rentOtherwisePay': 1600,
   'discountRate': 0.05,
   'monthlyMaintenance': 200,
   'scenarioName': 'With a Roommate',
   'beginsExpanded': false,
   'taxRate': 0.35,
   'scenarioCode': 'C',
   'inflation': 0.01
  },
  {'homePrice': 250000,
   'downpaymentPercentage': 0.2,
   'interestRate': 0.05,
   'loanTerm': 30,
   'roommateIncome': 0,
   'realtorCommission': 0.07,
   'annualAppreciation': 0.01,
   'rentOtherwisePay': 1600,
   'discountRate': 0.05,
   'monthlyMaintenance': 200,
   'scenarioName': '5% Interest Rate',
   'beginsExpanded': false,
   'taxRate': 0.35,
   'scenarioCode': 'D',
   'inflation': 0.01
  },
  {'homePrice': 250000,
   'downpaymentPercentage': 0.2,
   'interestRate': 0.03,
   'loanTerm': 30,
   'roommateIncome': 0,
   'realtorCommission': 0.07,
   'annualAppreciation': 0.01,
   'rentOtherwisePay': 1600,
   'discountRate': 0.05,
   'monthlyMaintenance': 200,
   'scenarioName': '3% Interest Rate',
   'beginsExpanded': false,
   'taxRate': 0.35,
   'scenarioCode': 'E',
   'inflation': 0.01
  }
];

const initialPlotData = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800,
    "amt": 2500
  },
  {
    "name": "Page G",
    "uv": 3490,
    "amt": 2100
  }
]

function App() {
    const [scenarioInputs, setScenarioInputs] = useState(defaultScenarioInputs);
    const [plotData, setPlotData] = useState(initialPlotData);
    //
    // const addScenario = useCallback(() => {
    //     scenarioInputs.push(newScenario);
    //     const updatedScenarios = scenarioInputs;
    //     setScenarioInputs(updatedScenarios);
    //     console.log('adding scenario')
    // }, [setScenarioInputs, scenarioInputs]);

    const calcOneNpv = (scenario) => {
        const monthlyInterestRate = scenario.interestRate / 12;
        const durationMonths = scenario.loanTerm * 12;
        let outstandingPrincipal = scenario.homePrice * (1 - scenario.downpaymentPercentage);
        const mortgagePayment = monthlyInterestRate * outstandingPrincipal * Math.pow(1 + monthlyInterestRate, durationMonths) / (Math.pow(1 + monthlyInterestRate, durationMonths) - 1)
        const downPayment = scenario.homePrice * scenario.downpaymentPercentage;
        const monthlyAppreciation = scenario.annualAppreciation / 12;
        // let interestPaid = 0;
        // let netCash = -downPayment;
        let npvs = [];
        let npv = -downPayment;
        let monthlyCashFlows = [];
        // let cumTaxDeduction = 0;
        const pmiCost = (principal) => {
            if (principal > 0.8 * scenario.homePrice) {
                return principal * 0.011 / 12
            } else {
                return 0
            }
        };
        for (let month = 0; month < durationMonths; month++) {
            const monthlyInterest = outstandingPrincipal * monthlyInterestRate;
            const monthlyPrincipal = mortgagePayment - monthlyInterest;
            // cumTaxDeduction += monthlyInterest * scenario.taxRate * 0;
            const pmiAmount = pmiCost(outstandingPrincipal);
            const monthlyCashFlow = Math.pow(1 + scenario.inflation / 12, month) * (scenario.rentOtherwisePay + scenario.roommateIncome - scenario.monthlyMaintenance) - pmiAmount - mortgagePayment;
            monthlyCashFlows.push(monthlyCashFlow);
            npv += monthlyCashFlow / Math.pow(1 + scenario.discountRate / 12, month);
            // netCash += monthlyCashFlow;
            outstandingPrincipal += -monthlyPrincipal;
            // interestPaid += monthlyInterest;

            const salePrice = scenario.homePrice * Math.pow(1 + monthlyAppreciation, month);
            const saleCash = salePrice * (1 - scenario.realtorCommission) - outstandingPrincipal;
            const newNpv = npv + saleCash / Math.pow(1 + scenario.discountRate / 12, month)
            // npvs.push(newNpv);


            // const summedMonthlyCashflows = monthlyCashFlows.reduce((a, b) => a + b, 0);
            // const AoverC = summedMonthlyCashflows / downPayment;
            // // let irr0 = Math.pow( AoverC, 2 / (month + 1)) - 1;
            // // let irr1 = Math.log(AoverC) / Math.log(summedMonthlyCashflows / (newNpv - downPayment));
            // let irr0 = .02;
            // let irr1 = 0.07;
            // for (let i = 1; i < 20; i++) {
            //     irr1 = irr1 - newNpv * ((irr1 - irr0) / (newNpv - npv));
            //     irr0 = irr1;
            // }
            npvs.push(newNpv);
        }
        return npvs
    };

    const calcNpv = useCallback((e, rowNumber, changedValue) => {
        let newScenarioInputs = scenarioInputs;
        //
        for (let i = 0; i < newScenarioInputs.length; i++) {
            if (i === rowNumber) {
                console.log(e.target.value);
                if (changedValue === "rentOtherwisePay" || changedValue === "roommateIncome") {
                    newScenarioInputs[rowNumber][changedValue] = parseInt(e.target.value);
                } else {
                    newScenarioInputs[rowNumber][changedValue] = e.target.value;
                }

                console.log(newScenarioInputs)
            }
        }

        const scenarioOutputs = newScenarioInputs.map(x => calcOneNpv(x));
        console.log(scenarioOutputs);
        // console.log(scenarioOutputs);
        console.log(scenarioOutputs);
        setScenarioInputs(scenarioInputs);
        const maxLoanTerm = Math.max(...newScenarioInputs.map(x => x.loanTerm * 12));
        let plotData = [];
        for (let j = 0; j < maxLoanTerm; j = j + 3) {
            let newPoint = {"month": j};
            for (let i = 0; i < scenarioOutputs.length; i++) {
                if (j < scenarioOutputs[i].length) {
                    newPoint[newScenarioInputs[i].scenarioName] = scenarioOutputs[i][j]
                }
            }
            plotData.push(newPoint);
        }
        setPlotData(plotData)
    }, [scenarioInputs, setPlotData, setScenarioInputs]);

    useEffect(() => {
        let newScenarioInputs = scenarioInputs;
        console.log(newScenarioInputs);
        const scenarioOutputs = newScenarioInputs.map(x => calcOneNpv(x));
        const maxLoanTerm = Math.max(...newScenarioInputs.map(x => x.loanTerm * 12));
        let plotData = [];
        for (let j = 0; j < maxLoanTerm; j = j + 3) {
            let newPoint = {"month": j};
            for (let i = 0; i < scenarioOutputs.length; i++) {
                if (j < scenarioOutputs[i].length) {
                    newPoint[newScenarioInputs[i].scenarioName] = scenarioOutputs[i][j]
                }
            }
            plotData.push(newPoint);
        }
        setPlotData(plotData)
    }, [scenarioInputs, setPlotData]);

    return (
        <Box width="95%">
            <Grid container  justify="center" >
                <Grid item alignContent={"center"} my={1}>
                    <Typography variant="h3" component="h2">
                      Should I Rent or Buy?
                    </Typography>
                </Grid>
            </Grid>
            <Box my={1}>
                <Paper elevation={3} variant={"outlined"}>
                    <Typography variant="h5" component="h2">
                      The net present value of buying, owning, and selling a home; vs. renting, is shown below, with months of ownership on the x-axis.
                    </Typography>
                    <Typography variant="h5" component="h2">
                      A negative number means you are better off renting!
                    </Typography>
                    <Typography variant="h5" component="h2">
                        Five scenarios are prepopulated with some simple cases. Add your own data to see what's right for you.
                    </Typography>
                </Paper>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box component="span" >
                        <Plotter plotData={plotData} scenarioInputs={scenarioInputs}/>
                    </Box>
                    <Paper elevation={3} variant={"outlined"}>
                        {
                            scenarioInputs.map((row, index) => (
                                <CaseRow
                                    homePrice={row.homePrice}
                                    downpaymentPercentage={row.downpaymentPercentage}
                                    interestRate={row.interestRate}
                                    loanTerm={row.loanTerm}
                                    roommateIncome={row.roommateIncome}
                                    realtorCommission={row.realtorCommission}
                                    annualAppreciation={row.annualAppreciation}
                                    rentOtherwisePay={row.rentOtherwisePay}
                                    discountRate={row.discountRate}
                                    monthlyMaintenance={row.monthlyMaintenance}
                                    scenarioName={row.scenarioName}
                                    beginsExpanded={row.beginsExpanded}
                                    textChange={calcNpv}
                                    index={index}
                                    inflation={row.inflation}

                                />
                            )
                            )
                        }
                    </Paper>
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
        </Box>
    );
}

// ReactDOM.render(<App />, document.querySelector('#app'));
export default App;